import React from 'react';
import Layout from '../js/components/Layout';

export default () => {
  return (
    <Layout>
      <div className="page-not-found">
        <h1>Sorry, this page does not exist.</h1>
      </div>
    </Layout>
  );
};
